@import "/styles/variables.module.scss";

.footer {
  background-color: $white-color;
  position: relative;
  &:before {
    content: "";
    background-image: url("../" + $bg-base-path + "/papercuteffact.svg");
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    top: -80px;
    z-index: 0;
    @include mobile_device {
      height: 40px;
      top: -40px;
    }
  }
  .footerTop {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    @include mobile_device {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .connection {
      @include medium_device {
        margin-bottom: 15px;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 20px;
        margin-right: 20px;
      }
    }
    .download {
      > ul {
        @include medium_device {
          justify-content: center !important;
        }
        > li {
          margin: 0 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .global-menu {
      .dropdown-menu {
        right: 0;
        min-width: 150px;
        left: auto !important;
      }
      .dropdown-btn {
        background: $theme-color;
        color: $white-color;
        padding: 10px 30px;
        width: 120px;
        text-align: left;
        &::before {
          content: none;
        }
        &:after {
          content: "";
          background-image: url("../" + $bg-base-path + "/arrow-down-w.svg");
          width: 12px;
          height: 6px;
          position: absolute;
          background-repeat: no-repeat;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          background-size: contain;
          right: 15px;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
  .footerPayment {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ddd;
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }
  .footerCenter {
    padding: 0 0 30px;
    [class^="col-"] {
      width: 18%;
      @include large_device {
        width: 25%;
      }
      @include medium_device {
        width: 50%;
      }
      ul {
        li {
          color: $desc-font;
          margin-top: 15px;
          @include medium_device {
            margin-top: 10px;
          }
        }
      }
    }
    .footerInfo {
      width: 28%;
      @include large_device {
        width: 100%;
      }
      ul {
        @include large_device {
          -webkit-column-count: 2;
          column-count: 2;
        }
        @include mobile_device {
          -webkit-column-count: 1;
          column-count: 1;
        }
      }
    }
    .Fsocial {
      li {
        margin-top: 0 !important;
        span {
          width: 20px;
          margin-right: 20px;
        }
      }
    }
    .footerWidget {
      h3 {
        @include large_device {
          margin-bottom: 20px;
        }
      }
      &.footerInfo {
        @include large_device {
          width: 100%;
        }
      }
      .contactList {
        margin-bottom: 30px;
      }
      .title {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: 26px;
        letter-spacing: 0.5px;
        color: #2b3648;
        margin-top: 30px;
        @include medium_device {
          font-size: 16px;
        }
      }
      ul {
        li {
          color: $desc-font;
          word-break: break-all;
          margin-top: 15px;
          @include mobile_device {
            font-size: 14px;
            margin-top: 10px;
          }
          a {
            color: $desc-font;
            text-decoration: none;
            display: inline-block;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
  .copyright {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid #ddd;
    @include medium_device {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }
    .copytext {
      @include medium_device {
        order: 1;
      }
    }
    p {
      color: $desc-font;
      @include mobile_device {
        font-size: 14px;
      }
    }
    .dmcicon {
      @include medium_device {
        margin-bottom: 8px;
        & > div {
          justify-content: center;
        }
      }
    }
  }
  .offerBanner {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    background: repeat scroll 0 0 $black-color;
    background-size: cover;
    z-index: 9;
    display: none;
    @include mobile_device {
      padding: 20px 0;
    }
    &.visible {
      display: block;
    }
    .offerContentWrapper {
      position: relative;
      @include mobile_device {
        flex-wrap: wrap;
      }
      .offerImage {
        @include up_large {
          margin-left: 20px;
        }
        @include mobile_device {
          text-align: center;
          flex: 0 1 calc(50% - 25px);
          // margin: 0 auto 15px 25px;
          margin: 0 auto 5px 25px;
        }
      }
      .offerContent {
        @include medium_device {
          flex-wrap: wrap;
          flex: 1 1 100%;
        }
      }
      .offerTextWrapper {
        margin-right: 40px;
        margin-left: 40px;
        @include extra_large {
          margin-left: 20px;
          margin-right: 20px;
        }
        @include large_device {
          margin-left: 12px;
          margin-right: 12px;
          flex: 1;
        }
        @include medium_device {
          margin-left: 10px;
          margin-right: 10px;
        }
        @include mobile_device {
          flex: 0 1 calc(50% - 10px);
          margin-left: 0;
        }
        @include extra_small {
          margin-right: 5px;
        }
      }
      .close {
        position: absolute;
        right: 0;
        left: auto;
        top: 25px;
        z-index: 1;
        cursor: pointer;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        @include mobile_device {
          top: 8px;
        }
        svg {
          path {
            stroke: $white-color;
          }
        }
      }
      .offerMsg {
        color: $white-color;
        // font-size: 30px;
        font-size: clamp(25px, 30px, 25px);
        @include up_large {
          // font-size: 28px;
          font-size: clamp(23px, 28px, 23px);
        }
        @include extra_large {
          // font-size: 22px;
          font-size: clamp(16px, 18px, 23px);
        }
        @include medium_device {
          font-size: clamp(16px, 18px, 20px);
        }
        @include mobile_device {
          line-height: 1;
          // font-size: 16px;
          font-size: clamp(16px, 16px, 23px);
        }
        @include extra_small {
          font-size: clamp(13px, 13px, 16px);
        }
        .highlight-text {
          color: $secondary-color;
          font-weight: 700;
        }
      }
      .orderBtn {
        width: auto;
        // flex: 0 1 auto;
        flex: none;
        @include medium_device {
          padding: 8px 12px;
          font-size: 16px;
        }
        @include mobile_device {
          flex: 0 1 auto;
          margin-top: 15px;
        }
      }
    }
  }
  &.footerBanner {
    padding-bottom: 130px;
    @include medium_device {
      padding-bottom: 150px;
    }
    @include mobile_device {
      padding-bottom: 180px;
    }
  }
}
.languageDropdown {
  max-width: 200px;
  @include medium_device {
    max-width: 100%;
    margin-bottom: 20px;
  }
  &[class*="show"] {
    svg {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  .dropdownMenu{
    width: 100%;
    background: $white-color;
    border: 1px solid $theme-color;
    -webkit-box-shadow: 0 4px 12px rgba($theme-color, 0.1);
    box-shadow: 0 4px 12px rgba($theme-color, 0.1);
    border-radius: 6px;
    cursor: pointer;
    color: $theme-color;
    margin-top: 8px;

    [class*="dropdown-item"] {
      a {
        font-size: 15px;
        line-height: 24px;
        color: $theme-color;
        font-weight: 500;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
      
      &:hover,
      &:focus {
        background: none;
      }
    }
  }

  #languageDropdownToggle {
    background-color: transparent;
    color: $theme-color;
    border: 1px solid $theme-color;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 6px 6px 12px;
    width: 100%;
    &::after {
      content: initial;
    }
    svg {
      -webkit-transition: transform 0.2s, -webkit-transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s;
      fill: $theme-color;
      height: 30px;
      width: 30px;
      path {
        fill: $theme-color;
        stroke-width: 0.8px;
        stroke: $white-color;
      }
    }
  }
}
